import { _wlog } from '../utils/Logging';

const logDefaultCaseWarningReducer = (
  location: string,
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
) =>
  _wlog(
    `Executed action in reducer ('${location}') with an unknown type ('${type}') with payload ('${JSON.stringify(
      payload
    )}')`
  );

export default logDefaultCaseWarningReducer;
