import { createContext, useContext } from 'react';
import {
  MaintenanceState,
  MaintenanceStateReducerInput,
} from '../state/maintenanceState';

export const MaintenanceContext = createContext<
  | {
      state: MaintenanceState;
      dispatch: React.Dispatch<MaintenanceStateReducerInput>;
    }
  | undefined
>(undefined);

export const useMaintenance = () => {
  const context = useContext(MaintenanceContext);

  if (context === undefined) {
    throw new Error('useMaintenance must be used within a MaintenanceGuard');
  }

  return context;
};
