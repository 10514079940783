import {
  Box,
  FormLabel,
  LinearProgress,
  styled,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useResponsiveness from '../../../utils/useResponsiveness';
import useInterval from './useInterval';

const transportSlogans = [
  'Loading... Like a slow-moving freight train.',
  'Waiting is the hardest part... Loading!',
  'This loading screen is on a road trip.',
  "Cargo's loading, humor's unloading!",
  "Loading... So you don't have to carry the weight!",
  "Hold tight, we're trucking through this loading screen!",
  'Our loading screen is the tortoise, not the hare!',
  "Don't worry, we're just packing some laughs!",
  'Loading... The speed limit is humor!',
  'Traffic jam on the loading highway. Patience, please!',
];

const StyledCenterDiv = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
}));

const firstSlogan = Math.floor(Math.random() * transportSlogans.length);

type PropType = {
  hideLogo?: boolean;
};

const Loading = ({ hideLogo }: PropType) => {
  const {
    options: {
      breakpoints: { xs, sm },
    },
  } = useResponsiveness();
  const [activeSlogan, setActiveSlogan] = useState(firstSlogan);
  const [newSlogan, setNewSlogan] = useState(firstSlogan);
  const [opacity, setOpacity] = useState(1);

  useInterval(() => {
    setNewSlogan(Math.floor(Math.random() * transportSlogans.length));
  }, 2000);

  useEffect(() => {
    setOpacity(0);
    const timeout = setTimeout(() => {
      setActiveSlogan(newSlogan);
      setOpacity(1);
    }, 750);
    return () => clearTimeout(timeout);
  }, [newSlogan]);

  const isMobile = xs || sm;

  return (
    <StyledCenterDiv>
      <Box sx={{ mb: 1, visibility: hideLogo ? 'hidden' : 'visible' }}>
        <img
          src="/static/logo.svg"
          style={{
            width: 100,
            height: 67,
          }}
          alt="Rotra"
        />
      </Box>
      <Box sx={{ position: 'relative' }}>
        <FormLabel
          sx={{
            transition: 'opacity 0.75s linear',
            opacity,
          }}
        >
          <Typography
            sx={{
              fontSize: isMobile ? '0.875rem' : '1rem',
            }}
          >
            {transportSlogans[activeSlogan]}
          </Typography>
        </FormLabel>
        <FormLabel
          sx={{
            transition: 'opacity 0.75s linear',
            opacity: 1 - opacity,
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
          }}
        >
          <Typography
            sx={{
              fontSize: isMobile ? '0.875rem' : '1rem',
            }}
          >
            {transportSlogans[activeSlogan]}
          </Typography>
        </FormLabel>
      </Box>
      <Box px={2}>
        <LinearProgress
          sx={{ mx: 'auto', mt: 2, width: 300, maxWidth: '80%' }}
        />
      </Box>
    </StyledCenterDiv>
  );
};
export default Loading;
