import { Color } from '@mui/material';
import { amber, blue, blueGrey, grey, red } from '@mui/material/colors';
import { createTheme, CSSInterpolation } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    primaryLight: Palette['primary'];
    blueGrey: Pick<Color, 200 | 400 | 600>;
    accentBlue: Pick<Color, 'A100' | 'A200' | 'A400' | 'A700'>;
    accentGreen: Pick<Color, 'A100' | 'A200' | 'A400' | 'A700'>;
    accentRed: Pick<Color, 'A100' | 'A200' | 'A400' | 'A700'>;
  }

  interface TypeBackground {
    blueTint: string;
    blueGreyTint: string;
    yellowTint: string;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
    primaryLight?: PaletteOptions['primary'];
    blueGrey?: Pick<Color, 200 | 400 | 600>;
    accentBlue?: Pick<Color, 'A100' | 'A200' | 'A400' | 'A700'>;
    accentGreen?: Pick<Color, 'A100' | 'A200' | 'A400' | 'A700'>;
    accentRed?: Pick<Color, 'A100' | 'A200' | 'A400' | 'A700'>;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/Tabs' {
  interface TabsPropsIndicatorColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/Pagination' {
  interface PaginationPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    primaryLight: true;
    tertiary: true;
  }
}

interface InputOwnerState {
  startAdornment?: boolean;
  endAdornment?: boolean;
}

// Base colors, use these and only these to define palette colors
const baseColors: Record<string, Color> = {
  rotraBlue: {
    50: '#e7eaf0',
    100: '#c1c9db',
    200: '#99a7c2',
    300: '#7384a9',
    400: '#556b98',
    500: '#375288',
    600: '#314b80',
    700: '#294175',
    800: '#223868',
    900: '#192850',
    A100: blue[100],
    A200: blue[200],
    A400: blue[400],
    A700: blue[700],
  },
  rotraGreen: {
    50: '#e2f2ec',
    100: '#b9ded0',
    200: '#8fcab2',
    300: '#68b495',
    400: '#51a481',
    500: '#439470',
    600: '#3d8765',
    700: '#367757',
    800: '#2f674a',
    900: '#234b32',
    A100: '#d0eec6',
    A200: '#b1e3a2',
    A400: '#75cf5d',
    A700: '#34a22d',
  },
  amber,
  red,
  grey,
  blueGrey,
};

// BASE THEME
// NOTE MUI wants this in 2 steps
let theme = createTheme({
  /**
   * WHAT FUNCTION DO THE DIFFERENT PALETTE COLORS HAVE IN THE APP?
   *
   * primary: Main color for the app, used for larger interface elements mainly. Rotra Blue.
   * primaryLight: Lighter version of the primary color which can be used for buttons.
   * secondary: Secondary color for the app, used for Call to Action buttons mostly. Special color for the app.
   * tertiary: Tertiary color for the app, used for drawing attention to things in badges, chips, etc. Rotra Yellow.
   *
   * background: Different shades of off-white backgrounds.
   *
   * grey: Different shades of grey for the app, used for text, borders, dividers, backgrounds etc.
   * blueGrey: Different shades of blue-grey for the app, used for borders, dividers, backgrounds etc.
   *
   * accentBlue: Different shades of blue for the app, used for drawing attention to things.
   * accentGreen: Different shades of green for the app, used for drawing attention to things.
   * accentRed: Different shades of red for the app, used for drawing attention to things.
   *
   * info: built-in MUI color, used for info messages.
   * success: built-in MUI color, used for success messages.
   * warning: built-in MUI color, used for warning messages.
   * error: built-in MUI color, used for error messages.
   */
  palette: {
    tonalOffset: 0.3,
    // Rotra blue
    // NOTE this is the brand color which may be too dark for some use cases
    // NOTE auto-generates light, dark and contrastText colors
    primary: {
      main: baseColors.rotraBlue[900],
    },
    // Rotra green
    // NOTE auto-generates light, dark and contrastText colors
    secondary: {
      main: baseColors.rotraGreen[400],
    },
    background: {
      default: baseColors.grey[50],
      blueGreyTint: baseColors.blueGrey[50],
      blueTint: baseColors.rotraBlue[50],
      yellowTint: baseColors.amber[50],
    },
    blueGrey: {
      200: baseColors.blueGrey[200],
      400: baseColors.blueGrey[400],
      600: baseColors.blueGrey[600],
    },
    accentBlue: {
      A100: baseColors.rotraBlue.A100,
      A200: baseColors.rotraBlue.A200,
      A400: baseColors.rotraBlue.A400,
      A700: baseColors.rotraBlue.A700,
    },
    accentGreen: {
      A100: baseColors.rotraGreen.A100,
      A200: baseColors.rotraGreen.A200,
      A400: baseColors.rotraGreen.A400,
      A700: baseColors.rotraGreen.A700,
    },
    accentRed: {
      A100: baseColors.red.A100,
      A200: baseColors.red.A200,
      A400: baseColors.red.A400,
      A700: baseColors.red.A700,
    },
  },
  shape: {
    borderRadius: 4,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1410,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: 'rgb(255, 244, 229)',
          color: 'rgb(102, 60, 0)',
        },
      },
    },
    MuiInputBase: {
      /* NOTE: this logic makes sure Adornments are clickable and will focus the input field*/
      styleOverrides: {
        root: ({ theme: tmpTheme }): CSSInterpolation => ({
          "& [class*='-input']": {},
          variants: [
            {
              props: ({ ownerState }: { ownerState: InputOwnerState }) =>
                ownerState.endAdornment,
              style: {
                paddingRight: '0 !important',
              },
            },
            {
              props: ({ ownerState }: { ownerState: InputOwnerState }) =>
                ownerState.startAdornment,
              style: {
                paddingLeft: '0 !important',
              },
            },
            {
              props: ({ ownerState }: { ownerState: InputOwnerState }) =>
                ownerState.endAdornment,
              style: {
                "& [class*='-input']": {
                  paddingRight: `${tmpTheme.spacing(5)} !important`,
                },
              },
            },
            {
              props: ({ ownerState }: { ownerState: InputOwnerState }) =>
                ownerState.startAdornment,
              style: {
                "& [class*='-input']": {
                  paddingLeft: `${tmpTheme.spacing(5)} !important`,
                },
              },
            },
          ],
        }),
      },
    },
    /* NOTE: this logic makes sure Adornments are clickable and will focus the input field*/
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          position: 'absolute',
          right: 12,
        },
        positionStart: {
          position: 'absolute',
          left: 12,
        },
      },
    },
  },
});

theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  // augmentColor takes a color and creates a main, light, dark and contractText object
  palette: {
    // Rotra blue, but lighter, usable for buttons, chips, etc.
    // NOTE generates light, dark and contrastText colors
    primaryLight: theme.palette.augmentColor({
      color: {
        main: baseColors.rotraBlue['500'],
      },
      name: 'primaryLight',
    }),
    // Rotra yellow
    // NOTE generates light, dark and contrastText colors
    tertiary: theme.palette.augmentColor({
      color: {
        main: baseColors.amber['A400'],
      },
      name: 'tertiary',
    }),
  },
});

export default theme;
