import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState, useRef } from 'react';

export type BreakPointsType = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
};

type ResizeHandlerProps = {
  width: number;
  height: number;
  breakpoints: BreakPointsType;
};

type ResizeHandler = (argo: ResizeHandlerProps) => void;

const useResponsiveness = () => {
  const resizeHandlers: ResizeHandler[] = [];
  const registerResizeHandler = (handlerFn: ResizeHandler) => {
    if (!!handlerFn && typeof handlerFn === 'function') {
      resizeHandlers.push(handlerFn);
    } else {
      throw new Error(
        `registerResizeHandler needs to be called with a function as only argument ${typeof handlerFn}`
      );
    }
  };

  const theme = useTheme();
  const breakpointsRef = useRef<BreakPointsType>({
    xs: useMediaQuery(theme.breakpoints.down('sm')),
    sm: useMediaQuery(theme.breakpoints.down('md')),
    md: useMediaQuery(theme.breakpoints.down('lg')),
    lg: useMediaQuery(theme.breakpoints.down('xl')),
    xl: useMediaQuery(theme.breakpoints.down('xl')),
  });
  const widthRef = useRef(0);

  // from: https://reedbarger.com/how-to-create-a-usewindowsize-react-hook/
  const isSSR = typeof window !== 'undefined';
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  });

  breakpointsRef.current = {
    xs: useMediaQuery(theme.breakpoints.down('sm')),
    sm: useMediaQuery(theme.breakpoints.down('md')),
    md: useMediaQuery(theme.breakpoints.down('lg')),
    lg: useMediaQuery(theme.breakpoints.down('xl')),
    xl: useMediaQuery(theme.breakpoints.down('xl')),
  };

  useEffect(() => {
    let callbackTimeout: NodeJS.Timeout;

    function handleResize() {
      if (callbackTimeout) {
        clearTimeout(callbackTimeout);
      }
      callbackTimeout = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        if (widthRef.current !== window.innerWidth) {
          resizeHandlers.forEach((fn) => {
            if (fn && typeof fn === 'function') {
              try {
                fn({
                  width: window.innerWidth,
                  height: window.innerHeight,
                  breakpoints: breakpointsRef.current,
                });
              } catch (e) {
                // eslint-disable-next-line no-console
                console.error('resize handler error', e);
              }
            }
          });
        }
        widthRef.current = window.innerWidth;
      }, 150);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      if (callbackTimeout) {
        clearTimeout(callbackTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    options: {
      ...windowSize,
      breakpoints: breakpointsRef.current,
    },
    registerResizeHandler,
  };
};

export default useResponsiveness;
