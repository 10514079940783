import { lazy } from 'react';

// NOTE: was used to avoid failing while loading Lazy Components https://medium.com/@alonmiz1234/retry-dynamic-imports-with-react-lazy-c7755a7d557a
// if it fails to load Lazy Component it will try 5 more times with delay of 1 second each
export const lazyReactNaiveRetry: typeof lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error) {
      for (let i = 0; i < 5; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        try {
          return await importer();
        } catch {
          console.log('Retrying import');
        }
      }
      throw error;
    }
  };
  return lazy(retryImport);
};
