import { Alert, Snackbar } from '@mui/material';
import { useCallback, useState } from 'react';

import useUserAgent from '../../../utils/useUserAgent';

const UserAgentWarning = () => {
  const { isChromium } = useUserAgent();
  const [isUAWarningVisible, setIsUAWarningVisible] = useState(
    !isChromium && localStorage.getItem('uaWarningDismissed') === null
  );

  const dismissUAWarning = useCallback(() => {
    localStorage.setItem('uaWarningDismissed', 'true');
    setIsUAWarningVisible(false);
  }, []);

  return (
    <Snackbar open={isUAWarningVisible}>
      <Alert onClose={dismissUAWarning} severity="warning">
        Note that our supported web browser is Google Chrome. We recommend using
        a recent version of Google Chrome for the best performance of myRotra.
        You can download Google Chrome{' '}
        <a
          href="https://www.google.com/intl/nl/chrome/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </Alert>
    </Snackbar>
  );
};

export default UserAgentWarning;
