import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import NotFoundPage from '../pages/errors/NotFoundPage';

interface RoutesGuardProps {
  children: React.ReactNode;
}

function RoutesGuard({ children }: RoutesGuardProps) {
  const location = useLocation();

  const notFoundPath = location.pathname;

  return (
    <Switch>
      {/*
        Example: <Redirect from="/users/:id" to="/users/profile/:id" />
        Place Redirects above the {children} render
      */}
      {/*
       NOTE: START OLD ROUTES REDIRECTS
      */}
      <Redirect from="/quotations/contracts" to="/contracts" />
      <Redirect from="/quotations/contracts/:id" to="/contracts/:id" />
      <Redirect from="/quotations/spot-rates" to="/quotes/spot-rates" />
      <Redirect from="/quotations/spot-rates/:id" to="/quotes/spot-rates/:id" />
      <Redirect
        from="/quotations/book/quotation/:id"
        to="/quotes/spot-rates/:id/book"
      />
      <Redirect from="/quotations/requests" to="/quotes/quote-requests" />
      <Redirect
        from="/quotations/requests/:id"
        to="/quotes/quote-requests/:id"
      />
      <Redirect
        from="/quotations/requests/new"
        to="/quotes/quote-requests/new"
      />
      {/*
        NOTE: END OLD ROUTES REDIRECTS
      */}

      {children}
      <Route>
        <NotFoundPage notFoundPath={notFoundPath} />
      </Route>
    </Switch>
  );
}

export default RoutesGuard;
