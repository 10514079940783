import { Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const NotFoundPage = ({ notFoundPath: notFoundPathFromProps }) => {
  const location = useLocation();
  const {
    entityName,
    returnPath,
    notFoundPath: notFoundPathFromLocationState,
  } = location.state ? location.state : {};

  const notFoundEntityDisplay = entityName ?? 'page';
  const notFoundPathDisplay =
    notFoundPathFromLocationState ?? notFoundPathFromProps;
  const returnPathDisplay = returnPath ?? '/';
  const returnLabelDisplay =
    returnPathDisplay === '/' ? 'myRotra dashboard' : `${entityName}s overview`;

  return (
    <div
      style={{
        padding: '2rem 5rem',
        maxWidth: 700,
        margin: '0 auto',
      }}
    >
      <Typography gutterBottom variant="h5">
        Sorry, the {notFoundEntityDisplay} you were looking for doesn&apos;t
        exist.
      </Typography>
      {notFoundPathDisplay && (
        <Typography paragraph variant="subtitle2">
          Your were looking for: <i>{notFoundPathDisplay}</i>
        </Typography>
      )}
      <Typography>
        Go back to <Link to={returnPathDisplay}>{returnLabelDisplay}</Link> or
        contact us using the <i>Support</i> button on the bottom left.
      </Typography>
    </div>
  );
};

export default NotFoundPage;
