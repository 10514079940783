import {
  Alert,
  AlertTitle,
  AppBar,
  Hidden,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useMaintenance } from '../../utils/useMaintenance';

const PREFIX = 'MaintenancePage';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  drawerAndLogo: `${PREFIX}-drawerAndLogo`,
  logoContainer: `${PREFIX}-logoContainer`,
  logo: `${PREFIX}-logo`,
  content: `${PREFIX}-content`,
  notification: `${PREFIX}-notification`,
};

const StylesRoot = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.toolbar}`]: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr',
  },

  [`& .${classes.drawerAndLogo}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      gridRow: 1,
      gridColumn: 1,
    },
  },

  [`& .${classes.logoContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.logo}`]: {
    height: 40,
    marginRight: 12,
  },

  [`& .${classes.content}`]: {
    marginTop: '64px',
    padding: theme.spacing(3),
  },

  [`& .${classes.notification}`]: {
    marginBottom: theme.spacing(1),
  },
}));

function MaintenancePage() {
  const { state } = useMaintenance();

  return (
    <StylesRoot className={classes.root}>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <div className={classes.drawerAndLogo}>
            <Typography variant="h6" noWrap data-tour="navigation.appTitle">
              <div className={classes.logoContainer}>
                <img src="/static/logo.svg" className={classes.logo} alt="" />
                <Hidden smDown>myRotra</Hidden>
              </div>
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Alert severity="warning">
          <AlertTitle>myRotra under construction</AlertTitle>
          <div className={classes.notification}>
            <p>
              Our site is currently under construction. We apologize for the
              inconvenience while we work behind the scenes to improve your
              myRotra experience. Thank you for your patience and please try
              again later.
            </p>
            {state.maintenanceMessage && <p>{state.maintenanceMessage}</p>}
            <p>
              For urgent operational matters please contact our Air &amp; Ocean
              departments directly.
            </p>
          </div>
        </Alert>
      </div>
    </StylesRoot>
  );
}

export default MaintenancePage;
