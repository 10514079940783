import { createContext, useContext } from 'react';

type MapType = typeof window.google.maps;
type PlacesType = typeof window.google.maps.places;

export type GoogleType = MapType & {
  places?: PlacesType;
};

type GoogleMapsContextType = {
  hasError: boolean;
  errorMessage: string;
  isLoading: boolean;
  maps: GoogleType | undefined;
};

export const GoogleMapsContext = createContext<GoogleMapsContextType>({
  hasError: false,
  errorMessage: '',
  isLoading: true,
  maps: undefined,
});

export const useGoogleMapsLibrary = () => {
  const state = useContext(GoogleMapsContext);
  return [state];
};
