const isDevelopment = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const _log = (...optional: any) => {
  // Normal log if in development
  if (isDevelopment()) {
    // eslint-disable-next-line no-console
    console.log(...optional);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const _elog = (...optional: any) => {
  // Error log if in development
  if (isDevelopment()) {
    // eslint-disable-next-line no-console
    console.error(...optional);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const _wlog = (...optional: any) => {
  // Warning log if in development
  if (isDevelopment()) {
    // eslint-disable-next-line no-console
    console.warn(...optional);
  }
};
