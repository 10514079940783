import { Loader } from '@googlemaps/js-api-loader';
import React, { useEffect, useState } from 'react';
import { GoogleMapsContext } from './useGoogleMapsLibrary';

const API_KEY = process.env.VITE_GOOGLE_API as string;

interface GoogleMapsProviderProps {
  children: React.ReactNode;
}

export const GoogleMapsProvider = ({ children }: GoogleMapsProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    new Loader({
      apiKey:
        API_KEY || 'Google Maps API Key not provided in Environment settings',
      version: 'weekly',
      libraries: ['places'],
      language: 'en',
    })
      .load()
      .then(() => setIsLoading(false))
      .catch(() => {
        setErrorMessage('Google Maps Failed to load');
        setIsLoading(false);
        // NOTE: Error from the catch has no meaningfull information
      });
  }, []);

  return (
    <GoogleMapsContext.Provider
      value={{
        hasError: !!errorMessage,
        errorMessage,
        isLoading,
        maps: window.google?.maps,
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  );
};

export default GoogleMapsProvider;
