//  source: https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
const useUserAgent = () => {
  // NOTE this malfunctioned on chrome 94
  // const isChromium = window.chrome;
  // const winNav = window.navigator;
  // const vendorName = winNav.vendor;
  // const isOpera = typeof window.opr !== 'undefined';
  // const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  // const isIOSChrome = !!winNav.userAgent.match('CriOS');
  // return {
  //   isIOSChrome,
  //   isChromium:
  //     isChromium !== null &&
  //     typeof isChromium !== 'undefined' &&
  //     vendorName === 'Google Inc.' &&
  //     isOpera === false &&
  //     isIEedge === false,
  // };

  const isChromium = window.navigator.userAgent.match(/chrome|chromium|crios/i);
  const isIOSChrome = window.navigator.userAgent.match(/crios/i);
  const isTouchDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );

  return {
    isChromium,
    isIOSChrome,
    isTouchDevice,
  };
};

export default useUserAgent;
